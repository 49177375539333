import * as Configuration from './configuration'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import {PaymentMethod} from './payment-method'

/**
 * Renders all the available payment-providers and their accounts, if any
 * @component PaymentMethods
 * @returns {ReactNode}
 */
export function PaymentMethods() {
  const providerField = Forms.useField('#/properties/provider')
  const accountIdField = Forms.useField('#/properties/accountId')
  const {transactionType} = Payments.useTransactionContext()

  const brand = ReactRedux.useSelector((state) => Configuration.getBrand(state))

  const getPaymentMethods = React.useCallback(
    (state) => Payments.getSortedPaymentMethods(state, transactionType),
    [transactionType]
  )

  const {paymentMethods, accounts} = ReactRedux.useSelector(getPaymentMethods)
  // TODO: Added to hide LuxonPay from the lower list of payment methods and only show it if it
  // has previously been used used due to: "Withdrawals are made using a wallet ID, each successful
  // deposit returns a wallet ID for the user that should be stored by the merchant and used
  // to initiate a withdrawal in the API. Users are not able to access their wallet ID in the
  // application and it is never presented to the user so in order to make a withdrawal a user
  // must first have made a deposit and their wallet ID be stored by the merchant."

  const excludedIds = ['PiqLuxonpayWithdrawal', 'PiqBankVoltWithdrawal']

  const filteredPaymentMethods = paymentMethods.filter((method) => {
    return !excludedIds.includes(method.id)
  })

  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()

  const handleChange = React.useCallback(
    (provider, accountId = '') => {
      accountIdField.onChangeValue(accountId)
      providerField.onChangeValue(provider)
      dispatch(Data.paymentMethodSelected(provider))
    },
    [accountIdField.onChangeValue, providerField.onChangeValue, dispatch]
  )

  React.useEffect(() => {
    if (filteredPaymentMethods.length === 1 && R.isEmpty(accounts)) {
      handleChange(filteredPaymentMethods[0].id)
    }
  }, [filteredPaymentMethods, handleChange])

  const [hideAccounts, setHideAccounts] = React.useState(true)

  if (R.isEmpty(accounts) && R.isEmpty(filteredPaymentMethods)) {
    return (
      <ThemeUi.Grid
        sx={{
          pt: 0,
          placeItems: 'center',
          gridGap: 2,
        }}
      >
        <ThemeUi.Box
          sx={{
            color: 'muted',
            fontSize: '64px',
          }}
        >
          <Icons.Warning />
        </ThemeUi.Box>
        {translate('payment-methods.none-available')}
      </ThemeUi.Grid>
    )
  }

  return (
    <React.Fragment>
      {brand === 'legendz' ? (
        <ThemeUi.Flex
          sx={{
            pb: 1,
            pt: 0,
            fontSize: '12px',
          }}
        >
          {translate('payment-methods.statement')}
        </ThemeUi.Flex>
      ) : null}
      {R.not(R.isEmpty(accounts)) && (
        <React.Fragment>
          <ThemeUi.Heading as="h6" sx={{pb: 0}}>
            {translate('payment-methods.with-accounts')}
          </ThemeUi.Heading>
          <ThemeUi.Grid
            gap={[0, 1]}
            columns={[
              'repeat(auto-fit, minmax(150px, 1fr))',
              'repeat(auto-fit, minmax(150px, 0.5fr))',
            ]}
            sx={{
              pb: 0,
              gridAutoRows: 'minmax(100px, 1fr)',
            }}
          >
            {R.map(
              (account) => (
                <PaymentMethod
                  key={account.id}
                  feePercent={account.feePercent}
                  id={account.paymentMethodId}
                  lastSuccess={account.lastSuccess}
                  maskedAccount={account.maskedAccount}
                  maxCents={account.maxLimitCents}
                  minCents={account.minLimitCents}
                  name={`${account.type}-${account.id}`}
                  selected={accountIdField.value}
                  value={account.id}
                  type={R.toLower(account.type)}
                  onChange={(accountId) =>
                    handleChange(account.paymentMethodId, accountId)
                  }
                />
              ),
              // limit accounts to the most recent 2 and toggle to view more.
              hideAccounts ? accounts.slice(0, 2) : accounts
            )}
          </ThemeUi.Grid>
          <ThemeUi.Box
            onClick={() => setHideAccounts((hide) => !hide)}
            variant="links.text"
            sx={{
              fontSize: 1,
              mb: 2,
            }}
          >
            {hideAccounts
              ? translate('payment-methods.show-all-accounts')
              : translate('payment-methods.show-less-accounts')}
          </ThemeUi.Box>
          {R.not(R.isEmpty(filteredPaymentMethods)) && (
            <ThemeUi.Heading as="h6" sx={{pt: 1}}>
              {translate('payment-methods.without-accounts')}
            </ThemeUi.Heading>
          )}
        </React.Fragment>
      )}
      <ThemeUi.Grid
        gap={[0, 1]}
        pt={0}
        columns="repeat(auto-fit, minmax(125px, 0.5fr))"
        sx={{
          gridAutoRows: 'minmax(100px, 1fr)',
        }}
      >
        {R.map(
          (paymentMethod) => (
            <PaymentMethod
              key={paymentMethod.id}
              feePercent={paymentMethod.feePercent}
              id={paymentMethod.id}
              maxCents={paymentMethod.maxLimitCents}
              minCents={paymentMethod.minLimitCents}
              name={`${paymentMethod.type}-${paymentMethod.id}`}
              selected={providerField.value}
              type={R.toLower(paymentMethod.type)}
              value={paymentMethod.id}
              onChange={(provider) => handleChange(provider)}
            />
          ),
          filteredPaymentMethods
        )}
      </ThemeUi.Grid>
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'payment-methods.none-available',
    'payment-methods.with-accounts',
    'payment-methods.without-accounts',
    'payment-methods.show-all-accounts',
    'payment-methods.show-less-accounts',
    'payment-methods.statement',
  ],
  PaymentMethods
)
